import groupBy from "lodash-es/groupBy";

import { ContactItemForFilter } from "@/api/interfaces/SitesInterfaces";
import {
  FilterItem,
  FilterOption,
} from "@/components/DropdownFilter/DropdownFilter";
import { SiteItemForFilter } from "@/api/interfaces/SitesInterfaces";
import { SearchResult } from "@/api/interfaces/SearchResult";
import { CountryItem } from "@/api/CountriesApi";
import { PresetFilterItem, PresetItem } from "@/api/SitePresetsApi";

export function getSitesFilterItem(sites: SearchResult<SiteItemForFilter>) {
  const grouped = groupBy(sites.entities, (site) => site.country_name);

  return Object.keys(grouped).map(
    (key) =>
      ({
        header: key,
        options: grouped[key].map(
          (s) =>
            ({
              text: s.name,
              value: s.id,
            } as FilterOption)
        ),
      } as FilterItem)
  );
}

export function getPresetsFilterItem(
  presets: PresetFilterItem[],
  myPresets: PresetItem[]
) {
  const grouped = groupBy(presets, (site) => "Site Presets");
  if (!grouped["Site Presets"]) {
    grouped["Site Presets"] = presets;
  }

  grouped["My Presets"] = myPresets.map((p) => ({
    id: p.name,
    name: p.name,
    site_id: "",
  }));

  const result = Object.keys(grouped).map(
    (key) =>
      ({
        header: key,
        options: grouped[key].map(
          (s) =>
            ({
              text: s.name,
              value: s.id,
            } as FilterOption)
        ),
      } as FilterItem)
  );

  return result;
}

export function getContactsFilterOptions(contacts: ContactItemForFilter[]) {
  const grouped = groupBy(contacts, (c) => c.country_name);

  return Object.keys(grouped).map(
    (key) =>
      ({
        header: key,
        options: grouped[key].map(
          (s) =>
            ({
              text: s.contact_name,
              value: s.contact_id,
            } as FilterOption)
        ),
      } as FilterItem)
  );
}

export function getCountriesFilterOptions(countries: CountryItem[]) {
  return countries.map(
    (c) => ({ text: c.name, value: c.code } as FilterOption)
  );
}
