import { computed, defineComponent } from "vue";
import { UserStatus } from "@/api/interfaces/UserInterfaces";

export default defineComponent({
  name: "StatusRectangle",
  props: { status: String as () => UserStatus },
  components: {},
  setup(props) {
    const getStatusString = computed(() => {
      return props.status;
    });

    return {
      getStatusString,
      statusClass: computed(
        () => `status-${getStatusString.value?.toLowerCase()}`
      ),
    };
  },
});
