import { useStore } from "@/store/store";
import {
  computed,
  defineComponent,
  onBeforeMount,
  reactive,
  ref,
  watch,
} from "vue";
import { useRouter } from "vue-router";

import uniq from "lodash-es/uniq";
import intersection from "lodash-es/intersection";
import isEqual from "lodash-es/isEqual";

import { SortingDirection } from "../../interfaces/SortingItem";
import {
  applyActionToUser,
  applyActionToUsers,
  getOperationsForUserByStatus,
  getUserStatusAfterAction,
  UserAction,
  userActions,
  UserItem,
  UserStatus,
  userStatusList,
} from "@/api/interfaces/UserInterfaces";
import useUserRoles, {
  UserRole,
  userRolesList,
  getUserRoleTitle,
} from "@/uses/useUserRoles";

import { FilterObject } from "@/components/DropdownFilter/DropdownFilter";
import { SortingItem } from "@/interfaces/SortingItem";

import TableHeaderComponent from "@/components/TableHeaderComponent/TableHeaderComponent.vue";
import SearchInput from "@/components/SearchInput/SearchInput.vue";
import StatusRectangle from "@/components/StatusRectangle/StatusRectangle.vue";
import CustomPopup from "@/components/CustomPopup/CustomPopup.vue";
import { getUsersSearch } from "@/api/UsersApi";
import useUserRolesFormatter from "@/uses/useUserRolesFormatter";
import { getSitesForFilter } from "@/api/SitesApi";
import { getSitesFilterItem } from "@/utils/filtersUtils";
import { showError, showErrorOptional } from "@/utils/notificationUtils";

export default defineComponent({
  name: "UsersList",
  props: {},
  components: {
    SearchInput,
    TableHeaderComponent,
    StatusRectangle,
    CustomPopup,
  },
  setup() {
    const router = useRouter();
    const store = useStore();

    const userRoles = useUserRoles(store);

    const fieldNames = {
      userName: "name",
      userFirstName: "first_name",
      userLastName: "last_name",
      roles: "role_ids",
      siteId: "site_id",
      siteName: "site_name",
      status: "user_status",
    };

    const list = ref<{ data: UserItem[]; selected: UserItem[] }>({
      data: [],
      selected: [],
    });

    const state = reactive({
      search: "",
      filtersData: {
        roles: {
          items: [
            {
              options: userRolesList.map((r) => ({
                value: r,
                text: getUserRoleTitle(r),
              })),
            },
          ],
          values: [UserRole.SiteAdmin, UserRole.Clinician],
        } as FilterObject,
        sites: {
          items: [],
          values: [],
        } as FilterObject,
        statuses: {
          items: [
            {
              options: userStatusList.map((s) => ({
                value: s.toString(),
                text: s.toString(),
              })),
            },
          ],
          values: [],
        } as FilterObject,
      },
      paging: {
        page: 1,
        pageSize: 30,
        total: 0,
      },
      sorting: null as SortingItem[] | null,
      isLoading: false,
      deletePopup: {
        isVisible: false,
        user: null as UserItem | null,
      },
    });

    const updateUsers = async (clearPaging = false) => {
      if (clearPaging) {
        state.paging.page = 1;
      }

      state.isLoading = true;
      try {
        const users = await getUsersSearch({
          paging: {
            page_number: state.paging.page,
            amount_per_page: state.paging.pageSize,
          },
          search_pattern: state.search,
          filters: [
            { field: fieldNames.roles, values: state.filtersData.roles.values },
            {
              field: fieldNames.siteId,
              values: state.filtersData.sites.values,
            },
            {
              field: fieldNames.status,
              values: state.filtersData.statuses.values,
            },
          ].filter((f) => f.values.length),
          sorting: state.sorting ? state.sorting : undefined,
        });
        state.paging.total = users.total;
        list.value.data = users.entities;
      } catch (ex) {
        showErrorOptional("Error while updating users", ex);
        console.log(ex);
      } finally {
        state.isLoading = false;
      }
    };

    const filterKeys = ["roles", "sites", "statuses"];
    const clearFilters = (except?: any) => {
      if (except !== "search") state.search = "";

      filterKeys.forEach((key) => {
        if ((state.filtersData as any)[key] !== except) {
          (state.filtersData as any)[key].values = [];
        }
      });
    };

    let lastWatchTrigger: Date | null = null;
    watch(
      () => ({
        roles: state.filtersData.roles.values,
        sites: state.filtersData.sites.values,
        statuses: state.filtersData.statuses.values,
      }),
      (newValue: any, oldValue: any) => {
        if (!isEqual(newValue, oldValue)) {
          if (
            lastWatchTrigger &&
            (new Date() as any) - (lastWatchTrigger as any) < 50
          ) {
            return;
          }

          lastWatchTrigger = new Date();

          filterKeys.forEach((key) => {
            if (!isEqual(newValue[key], oldValue[key])) {
              (state.filtersData as any)[key].values = newValue[key];
              clearFilters((state.filtersData as any)[key]);
            }
          });

          updateUsers(true);
        }
      }
    );

    onBeforeMount(() => {
      if (userRoles.hasRole(UserRole.SystemAdmin)) {
        getSitesForFilter().then((sites) => {
          state.filtersData.sites.items = getSitesFilterItem(sites);
        });
      }
      updateUsers();
    });

    const operationForStatus = computed(() => getOperationsForUserByStatus);

    const currentBulkOperations = computed(() => {
      const uniqStatuses = uniq(list.value.selected.map((u) => u.user_status));
      const finalStatuses = intersection(
        ...uniqStatuses.map((s) =>
          operationForStatus
            .value(s as UserStatus, store.state.auth.userRoles || [])
            .map((o) => o.action)
        )
      );
      return [
        userActions[UserAction.Activate],
        userActions[UserAction.ResendEmail],
        userActions[UserAction.Deactivate],
      ].filter((a) => finalStatuses.includes(a.action));
    });

    const handleAction = (action: UserAction, user: UserItem) => {
      // if (action === UserAction.Delete) {
      //   state.deletePopup.isVisible = true;
      //   state.deletePopup.user = user;
      // } else {
      applyActionToUser(action, user, store.state.auth.userRoles || [])
        .then(() => {
          user.user_status = getUserStatusAfterAction(user.user_status, action);
        })
        .catch((err) => {
          console.log(err);
        });
      // }
    };

    const handleBulkAction = (action: UserAction, users: UserItem[]) => {
      applyActionToUsers(action, users)
        .then(() => {
          users.forEach((user: UserItem) => user.user_status = getUserStatusAfterAction(user.user_status, action));
        })
        .catch((err) => {
          console.log(err);
        });
    };

    return {
      state,
      list,
      fieldNames,
      currentBulkOperations,
      ...userRoles,
      ...useUserRolesFormatter(),
      getSortingValueForField: computed(() => (field: string) => {
        if (state.sorting) {
          const sorting = state.sorting[0];
          return sorting.field === field ? sorting.direction : null;
        }
        return null;
      }),
      operationForStatus,
      addUser: () => {
        router.push(`/users/add-user`);
      },
      viewUser: (user: UserItem) => {
        router.push(`/users/view-user/${user.user_id}`);
      },
      pageChange: (page: number) => {
        state.paging.page = page;
        updateUsers();
      },
      searchChanged() {
        lastWatchTrigger = new Date();
        state.search;
        clearFilters("search");
        updateUsers(true);
      },
      sortingChange(field: string, direction: SortingDirection | null) {
        console.log("sortingChange", field);
        if (direction === null) {
          state.sorting = null;
        } else {
          state.sorting = [{ field, direction }];
          if (field === fieldNames.userFirstName) {
            state.sorting.push({ field: fieldNames.userLastName, direction });
          }
        }
        updateUsers(true);
      },
      selectionChange(val: UserItem[]) {
        list.value.selected = val;
      },
      handleAction,
      handleBulkAction,
      // deleteFromPopup(needToDelete: boolean) {
      //   if (needToDelete) {
      //     applyActionToUser(
      //       UserAction.Delete,
      //       state.deletePopup.user as UserItem,
      //       store.state.auth.userRoles || []
      //     ).then(() => {
      //       updateUsers();
      //     });
      //   }
      //   state.deletePopup.isVisible = false;
      // },
    };
  },
});
