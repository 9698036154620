import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_dialog = _resolveComponent("el-dialog")

  return (_openBlock(), _createBlock(_component_el_dialog, {
    title: _ctx.title,
    modelValue: _ctx.visible,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.visible = $event)),
    width: "45%",
    top: "30vh"
  }, {
    footer: _withCtx(() => [
      _createVNode("span", _hoisted_1, [
        _createVNode("button", {
          class: "button secondary",
          style: {"margin-right":"16px"},
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.cancel()))
        }, " Cancel "),
        _createVNode("button", {
          class: "button",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.ok()))
        }, " Yes ")
      ])
    ]),
    default: _withCtx(() => [
      _createVNode("span", null, _toDisplayString(_ctx.text), 1)
    ]),
    _: 1
  }, 8, ["title", "modelValue"]))
}