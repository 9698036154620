import { UserRole } from "@/uses/useUserRoles";

export default function useUserRolesFormatter() {
  return {
    formatUserRoles(roles: UserRole[] | null) {
      if (roles) {
        return roles.join(", ");
      }
      return "";
    },
  };
}
