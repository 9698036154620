import { axiosInstance as axios } from "./interceptor";
// import axios from "axios";

import { getURLFunc } from "./apiUtils";
import { SearchRequestParams } from "@/interfaces/SearchRequestParams";

import {
  UserAction,
  UserItem,
  UserProfile
} from "@/api/interfaces/UserInterfaces";
import { SearchResult } from "@/api/interfaces/SearchResult";

const getUrl = (siteId?: string) =>
  getURLFunc(`/study/api/${siteId ? `sites/${siteId}/users` : "users"}`);

export function viewUserProfile(userId: string) {
  return axios.get<UserProfile>(getUrl()(`/${userId}`)).then((r) => r.data);
}

export function getUsersSearch(params: SearchRequestParams) {
  return axios
    .post<SearchResult<UserItem>>(getUrl()("/search"), params)
    .then((r) => r.data);
}

export function createUser(user: UserProfile) {
  return axios
    .post<{ user_id: string }>(getUrl(user.site_id)(""), {
      ...user,
      // site_id: "fc500ea4-c07c-4cd0-b342-31aca28fecf7",
    })
    .then((r) => r.data);
}

export function editUser(user: UserProfile, siteId?: string) {
  return axios
    .put<void>(getUrl(siteId)(`/${user.id}`), {
      ...user,
      // site_id: "fc500ea4-c07c-4cd0-b342-31aca28fecf7",
    })
    .then((r) => r.data);
}

export function deleteUser(userId: string) {
  return axios.delete<void>(getUrl()(`/${userId}`)).then((r) => r.data);
}

export function getUserRoles(userId: string) {
  return axios.post<void>(getUrl()(`/${userId}/roles`)).then((r) => r.data);
}

export function sendUserAction(
  user: UserProfile | UserItem,
  action: UserAction
) {
  const id = (user as UserProfile).id || (user as UserItem).user_id;
  const status = (user as UserProfile).status || (user as UserItem).user_status;

  switch (action) {
    case UserAction.Activate:
    case UserAction.ResendEmail: {
      return axios
        .post(getUrl(user.site_id)(`/${id}/activation-emails`))
        .then((r) => r.data);
    }
    case UserAction.Deactivate: {
      return axios
        .put(getUrl(user.site_id)(`/${id}/deactivate`))
        .then((r) => r.data);
    }
    // case UserAction.Delete: {
    //   return axios.delete(getUrl()(`/${id}`)).then((r) => r.data);
    // }
    case UserAction.ResetPassword: {
      return axios
        .post(getUrl()(`/${id}/reset-password`))
        .then((r) => r.data);
    }
  }
}

export function sendUsersAction(
  users: UserItem[],
  action: UserAction
) {
  switch (action) {
    case UserAction.Activate:
    case UserAction.ResendEmail: {
      return axios
        .post(getUrl()(`/activation-emails`), users.map((user: UserItem) => user.user_id))
        .then((r) => r.data);
    }
    case UserAction.Deactivate: {
      const ids = users.map((user: UserItem) => user.user_id);
      return axios
        .put(getUrl()(`/deactivate`), ids)
        .then((r) => r.data);
    }
  }
}
