import { axiosInstance as axios } from "./interceptor";
// import axios from "axios";
import { getURLFunc } from "./apiUtils";
import { SearchRequestParams } from "@/interfaces/SearchRequestParams";
import { SearchResult } from "@/api/interfaces/SearchResult";
import {
  ContactItemForFilter,
  SiteAction,
  SiteItem,
  SiteItemForFilter,
  SiteProfile,
} from "./interfaces/SitesInterfaces";

const getUrl = getURLFunc("/study/api");

export async function getSitesForFilter() {
  return axios
    .post<SearchResult<SiteItemForFilter>>(getUrl("/sites/filters"), {
      paging: {
        page_number: 1,
        amount_per_page: 999999,
      },
    })
    .then((r) => r.data);
}

export async function getContactsForFilter() {
  return Promise.resolve([
    { contact_id: "1", contact_name: "Contact Stub 1", country_name: "China" },
    { contact_id: "2", contact_name: "Contact Stub 2", country_name: "China" },
    { contact_id: "3", contact_name: "Contact Stub 3", country_name: "UK" },
  ] as ContactItemForFilter[]);

  // return axios
  //   .post<SiteItemForFilter>(
  //     getUrl("/api/sites/filters")
  //   )
  //   .then((r) => r.data);
}

export function viewSiteProfile(site_id: string) {
  return axios
    .get<SiteProfile>(getUrl(`/sites/${site_id}`))
    .then((r) => r.data);
}

export function getSitesSearch(params: SearchRequestParams) {
  return axios
    .post<SearchResult<SiteItem>>(getUrl("/sites/search"), params)
    .then((r) => r.data);
}

export function createSite(site: SiteProfile) {
  return axios
    .post<string>(getUrl("/sites"), {
      ...site,
    })
    .then((r) => r.data);
}

export function editSite(site: SiteProfile) {
  return axios
    .put<void>(getUrl(`/sites/${site.id}`), {
      ...site,
    })
    .then((r) => r.data);
}

export function deleteSite(site: SiteProfile) {
  return axios.delete<void>(getUrl(`${site.id}`)).then((r) => r.data);
}

export function sendSiteAction(
  site: SiteProfile | SiteItem,
  action: SiteAction
) {
  // const id = site.id;
  // switch (action) {
  //   case SiteAction.AssignToStudy:
  //     {
  //       console.log("not yet implemented");
  //     }
  //     break;
  //   case SiteAction.Delete:
  //     {
  //       console.log("not yet implemented");
  //       // return axios.delete(getUrl(`/${id}`)).then((r) => r.data);
  //     }
  //     break;
  // }
}
