import { defineComponent, reactive } from "vue";

export default defineComponent({
  name: "CustomPopup",
  props: { title: String, text: String, visible: Boolean },
  components: {},
  setup(props, { emit }) {
    return {
      ok() {
        emit("ok");
      },
      cancel() {
        emit("cancel");
      },
    };
  },
});
