import { ActionItem } from "@/interfaces/ActionItem";
import router from "@/router";
import { sendUserAction, sendUsersAction } from "../UsersApi";
import { UserRole } from "@/uses/useUserRoles";
export interface UserProfile {
  id?: string;
  first_name?: string;
  last_name?: string;
  title?: string;
  email?: string;
  country_code?: string;
  phone_number?: string;
  is_site_contact_person?: boolean;
  site_id?: string;
  status?: UserStatus;
  role_ids?: string[];
  created_by?: string;
  created_date?: string;
}

export enum UserStatus {
  Created = "Created",
  Pending = "Pending",
  Activated = "Activated",
  Deactivated = "Deactivated",
}

export const userStatusList = [
  UserStatus.Created,
  UserStatus.Pending,
  UserStatus.Activated,
  UserStatus.Deactivated,
];

export enum UserAction {
  View = "View",
  Activate = "Activate",
  ResendEmail = "ResendEmail",
  Deactivate = "Deactivate",
  ResetPassword = "ResetPassword",
  Edit = "Edit",
  // Delete = "Delete",
}

export const userActions: { [key in UserAction]: ActionItem } = {
  [UserAction.View]: { title: "View user details", action: UserAction.View },
  [UserAction.Activate]: { title: "Activate", action: UserAction.Activate },
  [UserAction.ResendEmail]: {
    title: "Resend activation email",
    action: UserAction.ResendEmail,
  },
  [UserAction.Deactivate]: {
    title: "Deactivate",
    action: UserAction.Deactivate,
  },
  [UserAction.ResetPassword]: {
    title: "Reset Password",
    action: UserAction.ResetPassword,
  },
  [UserAction.Edit]: {
    title: "Edit",
    action: UserAction.Edit,
  },
  // [UserAction.Delete]: {
  //   title: "Delete",
  //   action: UserAction.Delete,
  // },
};
export function getOperationsForUserByStatus(
  status: UserStatus,
  currentUserRoles: UserRole[]
) {
  switch (status) {
    case UserStatus.Created:
      return [
        userActions[UserAction.View],
        // userActions[UserAction.Edit],
        { ...userActions[UserAction.Edit], delemiterAfter: true },
        // { ...userActions[UserAction.Delete], delemiterAfter: true },
        userActions[UserAction.Activate],
        // actions[UserAction.ResendEmail],
        userActions[UserAction.Deactivate],
        // actions[UserAction.ResetPassword],
      ];
    case UserStatus.Pending:
      return [
        userActions[UserAction.View],
        { ...userActions[UserAction.Edit], delemiterAfter: true },
        userActions[UserAction.ResendEmail],
        userActions[UserAction.Deactivate],
      ];
    case UserStatus.Activated:
      return [
        userActions[UserAction.View],
        { ...userActions[UserAction.Edit], delemiterAfter: true },
        userActions[UserAction.Deactivate],
        userActions[UserAction.ResetPassword],
      ];
    case UserStatus.Deactivated:
      return [
        { ...userActions[UserAction.View], delemiterAfter: true },
        userActions[UserAction.Activate],
      ];
  }

  return [];
}

export function getUserStatusAfterAction(
  userStatus: UserStatus,
  action: UserAction
) {
  if (action === UserAction.Activate) {
    if (
      userStatus === UserStatus.Created ||
      userStatus === UserStatus.Deactivated
    ) {
      return UserStatus.Pending;
    } else {
      return UserStatus.Activated;
    }
  }
  if (action === UserAction.Deactivate) {
    return UserStatus.Deactivated;
  }
  return userStatus;
}

export async function applyActionToUser(
  action: UserAction,
  user: UserProfile | UserItem,
  currentUserRole: UserRole[]
) {
  const id = (user as UserProfile).id || (user as UserItem).user_id;

  switch (action) {
    case UserAction.View:
      router.push(`/users/view-user/${id}`);
      break;
    case UserAction.Edit:
      router.push(`/users/edit-user/${id}`);
      break;
    default:
      {
        await sendUserAction(user, action);
      }
      break;
  }
}

export async function applyActionToUsers(
  action: UserAction,
  users: UserItem[]
) {
  await sendUsersAction(users, action);
}

//Fields for filtering/sorting:
//Id, FirstName, LastName, Title,
//IsSiteContactPerson, RoleIds, Site.Name, Status.
export interface UserItem {
  user_id: string;
  first_name: string;
  last_name: string;
  role_ids: UserRole[];
  email: string;
  site_id: string;
  site_name: string;
  user_status: UserStatus;
}
